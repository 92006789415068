import styled from 'styled-components'

import { useLinkWrapper } from '../hooks/useLinkWrapper'

import * as S from '../styles'

type Crumb = {
    label: string
    path: string
}

export type BreadCrumbsProps = {
    crumbs: Crumb[]
} & React.HTMLAttributes<HTMLDivElement>

function BreadCrumbsComponent({
    crumbs,
    ...navProps
}: BreadCrumbsProps): React.ReactElement {
    const LinkWrapper = useLinkWrapper()
    return (
        <nav {...navProps} aria-label="Fil d'Ariane">
            <S.breadCrumb.List>
                {crumbs.map((crumb, index) => {
                    if (index !== crumbs.length - 1) {
                        return (
                            <S.breadCrumb.Crumb key={crumb.path}>
                                <LinkWrapper href={crumb.path}>
                                    <a>{crumb.label}</a>
                                </LinkWrapper>
                                <S.breadCrumb.Separator role="presentation">
                                    {'>'}
                                </S.breadCrumb.Separator>
                            </S.breadCrumb.Crumb>
                        )
                    }

                    return (
                        <S.breadCrumb.Crumb key={index}>
                            <span>{crumb.label}</span>
                        </S.breadCrumb.Crumb>
                    )
                })}
            </S.breadCrumb.List>
        </nav>
    )
}

export const BreadCrumbs = styled(BreadCrumbsComponent)``

type BreadCrumbsSkeletonProps = {
    className?: string
    width?: number
}

function BreadCrumbsSkeletonComponent({
    className,
    width = 20
}: BreadCrumbsSkeletonProps) {
    return (
        <SkeletonWrapper className={className}>
            <S.skeleton.Text size="small" width={width} />
        </SkeletonWrapper>
    )
}

export const BreadCrumbsSkeleton = styled(BreadCrumbsSkeletonComponent)``

const SkeletonWrapper = styled.div`
    width: 100%;
`
