import styled from 'styled-components'

import * as color from '../constants/colors'
import * as font from '../constants/fonts'

export const Crumb = styled.li`
    display: inline;
`

export const List = styled.ol`
    color: ${color.GRAY_3};
    font-family: ${font.SECONDARY};
    font-size: 10px;
    list-style: none;
    margin: 0;
    opacity: 50%;
    padding: 0;
`

export const Separator = styled.span`
    margin: 0 3px;
`
