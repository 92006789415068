import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["icon"];
import React from "react";
var __jsx = React.createElement;
import * as ds from '@bob/design-system';
export function MomentIcon(_ref) {
  var icon = _ref.icon,
      rest = _objectWithoutProperties(_ref, _excluded);

  switch (icon) {
    case 'cooking':
      {
        return __jsx(ds.stickers.moments.Cook, rest);
      }

    case 'decorating':
      {
        return __jsx(ds.stickers.moments.Decoration, rest);
      }

    case 'doing-housework':
      {
        return __jsx(ds.stickers.moments.Housework, rest);
      }

    case 'doing-sport':
      {
        return __jsx(ds.stickers.moments.Sport, rest);
      }

    case 'dressing':
      {
        return __jsx(ds.stickers.moments.Dressing, rest);
      }

    case 'eating':
      {
        return __jsx(ds.stickers.moments.Eat, rest);
      }

    case 'educating-onself':
      {
        return __jsx(ds.stickers.moments.Cultivate, rest);
      }

    case 'entertaining-oneself':
      {
        return __jsx(ds.stickers.moments.Entertain, rest);
      }

    case 'going-on-holiday':
      {
        return __jsx(ds.stickers.moments.Holiday, rest);
      }

    case 'going-to-bed':
      {
        return __jsx(ds.stickers.moments.GoToBed, rest);
      }

    case 'relaxing':
      {
        return __jsx(ds.stickers.moments.Relaxing, rest);
      }

    case 'snacking':
      {
        return __jsx(ds.stickers.moments.Nibble, rest);
      }

    case 'taking-an-aperitif':
      {
        return __jsx(ds.stickers.moments.Aperitif, rest);
      }

    case 'taking-breakfast':
      {
        return __jsx(ds.stickers.moments.Breakfast, rest);
      }

    case 'taking-care-of-children':
      {
        return __jsx(ds.stickers.moments.Children, rest);
      }

    case 'taking-care-of-oneself':
      {
        return __jsx(ds.stickers.moments.TakingCare, rest);
      }

    case 'taking-care-of-pets':
      {
        return __jsx(ds.stickers.moments.Animals, rest);
      }

    case 'travelling':
      {
        return __jsx(ds.stickers.moments.Shifting, rest);
      }

    case 'washing':
      {
        return __jsx(ds.stickers.moments.Wash, rest);
      }

    case 'working':
      {
        return __jsx(ds.stickers.moments.Work, rest);
      }
  }
}