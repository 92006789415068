export function getCategorySymbolName(slug: string): string {
    switch (slug) {
        case 'mode-et-accessoires':
            return 'Fashion'
        case 'hygiene-beaute-et-bien-etre':
            return 'Hygiene'
        case 'livres-et-papeterie':
            return 'Stationery'
        case 'alimentation':
            return 'Food'
        case 'jeux-jouets-et-loisirs':
            return 'Game'
        case 'puericulture':
            return 'Childcare'
        case 'maison-et-jardin':
            return 'House'
        case 'sport-et-plein-air':
            return 'Sports'
        case 'animaux':
            return 'Animals'
    }

    return ''
}

type Criterion = {
    extra: {
        icon: string
    }
}

export function getCriterionSymbolName(criterion: Criterion): string {
    switch (criterion.extra.icon) {
        case 'carbon-neutral': {
            return 'CarbonNeutral'
        }
        case 'circular-economy': {
            return 'CircularEconomy'
        }
        case 'fair-trade': {
            return 'FairTrade'
        }
        case 'inventory-optimized': {
            return 'OptimizedInventory'
        }
        case 'made-in-europe': {
            return 'MadeInEurope'
        }
        case 'made-in-france': {
            return 'MadeInFrance'
        }
        case 'natural-materials': {
            return 'NaturalMaterials'
        }
        case 'organic': {
            return 'Organic'
        }
        case 'recycled-materials': {
            return 'RecycledMaterials'
        }
        case 'short-supply-chain': {
            return 'ShortSupplyChain'
        }
        case 'sustainable': {
            return 'Sustainable'
        }
        case 'upcycled-materials': {
            return 'UpcycledMaterials'
        }
        case 'zero-waste': {
            return 'ZeroWaste'
        }
    }

    return ''
}
