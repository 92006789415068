import styled, { css } from 'styled-components'

import * as color from '../constants/colors'

export type WrapperProps = {
    placement?: 'top' | 'bottom' | 'left' | 'right'
    show?: boolean
}

const BaseWrapper = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;

    &::before,
    &::after {
        display: block;
        position: absolute;
        user-select: none;
        pointer-events: none;
        transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
    }
    &::before {
        z-index: 100;
        background: ${color.GRAY_3};
        border-radius: 140px;
        color: ${color.SAND_1};
        content: attr(data-tooltip);
        font-size: 12px;
        max-width: 400px;
        min-width: 100px;
        overflow: hidden;
        padding: 5px 10px 5px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &::after {
        z-index: 2;
        content: '';
        border: 6px solid transparent;
    }
`

export const _placementBottom = css`
    &::before {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(12px);
    }
    &::after {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-bottom-color: ${color.GRAY_3};
    }
`

export const _placementTop = css`
    &::before {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(-12px);
    }
    &::after {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-top-color: ${color.GRAY_3};
    }
`

export const _placementLeft = css`
    &::before {
        top: 50%;
        right: 100%;
        transform: translateY(-50%) translateX(-10px);
    }
    &::after {
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        border-left-color: ${color.GRAY_3};
    }
`

export const _placementRight = css`
    &::before {
        top: 50%;
        left: 100%;
        transform: translateY(-50%) translateX(10px);
    }
    &::after {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-right-color: ${color.GRAY_3};
    }
`

export const Wrapper = styled(BaseWrapper)<WrapperProps>`
    ${({ show }) => {
        return (
            show === undefined &&
            css`
                &:hover {
                    &::before,
                    &::after {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            `
        )
    }}

    ${({ show }) => {
        if (show)
            return css`
                &::before,
                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            `
        if (!show)
            return css`
                &::before,
                &::after {
                    visibility: hidden;
                    opacity: 0;
                }
            `
    }}


    ${({ placement }) => placement === 'bottom' && _placementBottom}

    ${({ placement }) => placement === 'top' && _placementTop}

    ${({ placement }) => placement === 'left' && _placementLeft}

    ${({ placement }) => placement === 'right' && _placementRight}
`
