import styled from 'styled-components'

import { SizedSvg } from '../SizedSvg'

export type StickerProps = React.SVGAttributes<SVGElement> & {
    children: React.ReactNode
    className?: string
    name: string
    color?: string
    theme?: string
    /** @default 125 */
    size?: number | string
    title?: string
    /** @default '0 0 125 125' */
    viewBox?: string
}

export type SpecificStickerProps = Omit<StickerProps, 'children' | 'name'> & {
    children?: React.ReactNode
}

/** Base SVG Sticker.stories.tsx Component */
function StickerComponent({
    children,
    className,
    color,
    theme,
    name,
    size = 125,
    title,
    viewBox = '0 0 125 125'
}: StickerProps): React.ReactElement<StickerProps> {
    return (
        <SizedSvg
            aria-labelledby={title && 'title'}
            className={className}
            clipRule="evenodd"
            color={color}
            theme={theme}
            fillRule="evenodd"
            height={size}
            id={`Sticker-${name}`}
            preserveAspectRatio="xMidYMid meet"
            $size={size}
            viewBox={viewBox}
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            {title && <title id="title">{name}</title>}
            {children}
        </SizedSvg>
    )
}

export const Sticker = styled(StickerComponent)<StickerProps>`
    background: ${props => props.theme};
    border-radius: 50%;
    overflow: hidden;
`
