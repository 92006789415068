import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    100% { transform: rotate(360deg) };
`

export const Text = styled.g`
    transform-origin: center;
    animation: ${spin} 30s linear infinite;
`

export const Icon = styled.g``
